exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-json-validator-tsx": () => import("./../../../src/pages/json-validator.tsx" /* webpackChunkName: "component---src-pages-json-validator-tsx" */),
  "component---src-pages-legals-js": () => import("./../../../src/pages/legals.js" /* webpackChunkName: "component---src-pages-legals-js" */),
  "component---src-pages-newsletters-tsx": () => import("./../../../src/pages/newsletters.tsx" /* webpackChunkName: "component---src-pages-newsletters-tsx" */),
  "component---src-pages-partnerships-js": () => import("./../../../src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-premium-js": () => import("./../../../src/pages/premium.js" /* webpackChunkName: "component---src-pages-premium-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-svg-to-jsx-tsx": () => import("./../../../src/pages/svg-to-jsx.tsx" /* webpackChunkName: "component---src-pages-svg-to-jsx-tsx" */),
  "component---src-templates-author-index-js": () => import("./../../../src/templates/author/index.js" /* webpackChunkName: "component---src-templates-author-index-js" */),
  "component---src-templates-category-post-list-tsx": () => import("./../../../src/templates/category-post-list.tsx" /* webpackChunkName: "component---src-templates-category-post-list-tsx" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-hub-article-tsx": () => import("./../../../src/templates/hub-article.tsx" /* webpackChunkName: "component---src-templates-hub-article-tsx" */),
  "component---src-templates-hub-tsx": () => import("./../../../src/templates/hub.tsx" /* webpackChunkName: "component---src-templates-hub-tsx" */),
  "component---src-templates-post-list-tsx": () => import("./../../../src/templates/post-list.tsx" /* webpackChunkName: "component---src-templates-post-list-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tag-list-index-js": () => import("./../../../src/templates/tagList/index.js" /* webpackChunkName: "component---src-templates-tag-list-index-js" */),
  "component---src-templates-teams-tsx": () => import("./../../../src/templates/teams.tsx" /* webpackChunkName: "component---src-templates-teams-tsx" */),
  "component---src-templates-user-submission-list-tsx": () => import("./../../../src/templates/user-submission-list.tsx" /* webpackChunkName: "component---src-templates-user-submission-list-tsx" */),
  "component---src-templates-user-submission-tsx": () => import("./../../../src/templates/user-submission.tsx" /* webpackChunkName: "component---src-templates-user-submission-tsx" */),
  "slice---src-slices-header-index-tsx": () => import("./../../../src/slices/header/index.tsx" /* webpackChunkName: "slice---src-slices-header-index-tsx" */),
  "slice---src-slices-subcategory-navigation-tsx": () => import("./../../../src/slices/subcategory-navigation.tsx" /* webpackChunkName: "slice---src-slices-subcategory-navigation-tsx" */)
}

